export default {
  namespaced: true,
  state: {
    checkedItem: [],
  },
  getters: {
    getCheckedItem(state) {
      return state.checkedItem;
    },
  },
  mutations: {
    addCheckedItem(state, payload) {
      state.checkedItem = payload;
    },
  },
};
