import { loginApi, fetchAdmin } from "@/api/seoAdminAPI";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  getters: {
    authToken(state) {
      return state.token;
    },
    authUser(state) {
      return state.user;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async attempt({ commit, dispatch }, payload) {
      commit("setToken", payload);
      try {
        const { data } = await fetchAdmin();
        commit("setUser", data.user);
      } catch (error) {
        dispatch("logout");
      }
    },

    async login({ dispatch }, payload) {
      try {
        const { data } = await loginApi(payload);
        localStorage.setItem("seoAdminToken", data.access_token);
        await dispatch("attempt", data.access_token);
        router.push({ name: "HomeView" });
      } catch (error) {
        alert("로그인 정보가 올바르지 않습니다.");
      }
    },

    logout({ commit }) {
      commit("setToken", null);
      commit("setUser", null);
      localStorage.removeItem("seoAdminToken");
      router.push("/login").catch(() => {});
    },
  },
};
