import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import auth from "@/store/auth";
import domain from "@/store/domain";

export default new Vuex.Store({
  modules: {
    auth,
    domain,
  },
});
