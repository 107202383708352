import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import store from "@/store";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "LoginView",
      component: () => import("@/views/LoginView.vue"),
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/authToken"]) {
          next({ name: "HomeView" });
          return;
        }
        next();
      },
    },

    {
      path: "/",
      name: "HomeView",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        requireLogin: true,
      },
      redirect: "/keyword",
    },

    /**
     * 계정 관리
     */
    {
      path: "/account",
      name: "AccountListView",
      component: () => import("@/views/account/AccountListView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/account/details/:id",
      name: "AccountDetailsView",
      component: () => import("@/views/account/AccountDetailsView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    {
      path: "/package",
      name: "PackageListView",
      component: () => import("@/views/package/PackageListView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/package/:id",
      name: "PackageDetailsView",
      component: () => import("@/views/package/PackageDetailsView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    /**
     * 도메인 관리
     */
    {
      path: "/domain/all-list",
      name: "DomainAllListView",
      component: () => import("@/views/domain/DomainAllListView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/domain/picked-list",
      name: "DomainPickedListView",
      component: () => import("@/views/domain/DomainPickedListView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    /**
     * 카테고리 관리
     */
    {
      path: "/category",
      name: "CategoryListView",
      component: () => import("@/views/category/CategoryListView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    /**
     * 키워드 관리
     */
    {
      path: "/keyword",
      name: "KeywordListView",
      component: () => import("@/views/keyword/KeywordListView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    {
      path: "/keyword-deleted-list",
      name: "KeywordDeletedListView",
      component: () => import("@/views/keyword/KeywordDeletedListView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    {
      path: "/keyword-domain",
      name: "KeywordDomainView",
      component: () => import("@/views/keyword/KeywordDomainView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    /**
     * 워드프레스 관리
     */
    // {
    //   path: "/wordpress/content-list",
    //   name: "WordpressContentListView",
    //   component: () => import("@/views/wordpress/WordpressContentListView.vue"),
    //   meta: {
    //     requireLogin: true,
    //   },
    // },
    {
      path: "/wordpress/account-manage",
      name: "WordpressAccountManageView",
      component: () =>
        import("@/views/wordpress/WordpressAccountManageView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/wordpress/log-manage",
      name: "WordpressLogManageView",
      component: () => import("@/views/wordpress/WordpressLogManageView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/wordpress/stat-manage",
      name: "WordpressStatManageView",
      component: () => import("@/views/wordpress/WordpressStatManageView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/wordpress/content-add",
      name: "WordpressContentAddView",
      component: () => import("@/views/wordpress/WordpressContentAddView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/wordpress/content-add-auto",
      name: "WordpressContentAddAutoView",
      component: () =>
        import("@/views/wordpress/WordpressContentAddAutoView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/wordpress/content-add-random",
      name: "WordpressContentAddRandomView",
      component: () =>
        import("@/views/wordpress/WordpressContentAddRandomView.vue"),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: "/wordpress/job-queue-list",
      name: "WordpressJobQueueListView",
      component: () =>
          import("@/views/wordpress/WordpressJobQueueListView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    /**
     * 레포트
     */
    {
      path: "/report/country",
      name: "ReportCountryView",
      component: () => import("@/views/report/ReportCountryView.vue"),
      meta: {
        requireLogin: true,
      }
    },
    {
      path: "/report/visit",
      name: "ReportVisitView",
      component: () => import("@/views/report/ReportVisitView.vue"),
      meta: {
        requireLogin: true,
      }
    },
    {
      path: "/report/content",
      name: "ReportContentView",
      component: () => import("@/views/report/ReportContentView.vue"),
      meta: {
        requireLogin: true,
      }
    },
    {
      path: "/report/generated",
      name: "ReportGeneratedView",
      component: () => import("@/views/report/ReportGeneratedView.vue"),
      meta: {
        requireLogin: true,
      }
    },
    {
      path: "/report/generated-weekly",
      name: "ReportGeneratedWeeklyView",
      component: () => import("@/views/report/ReportGeneratedWeeklyView.vue"),
      meta: {
        requireLogin: true,
      }
    },
    {
      path: "/report/index",
      name: "ReportIndexView",
      component: () => import("@/views/report/ReportIndexView.vue"),
      meta: {
        requireLogin: true,
      }
    },

    /**
     * 프롬프트 관리
     */
    {
      path: "/prompt",
      name: "PromptListView",
      component: () => import("@/views/prompt/PromptListView.vue"),
      meta: {
        requireLogin: true,
      },
    },

    /**
     * 챗GPT API 테스트
     */
    {
      path: "/gpt",
      name: "ChatApiTestView",
      component: () => import("@/views/gpt/ChatApiTestView.vue"),
      meta: {
        requireLogin: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireLogin && !store.getters["auth/authToken"]) {
    next({ name: "LoginView" });
    return;
  }
  next();
});

export default router;
