export const menu = [
  {
    icon: "mdi-account-multiple",
    title: "계정 관리",
    path: "/account",
  },
  {
    icon: "mdi-package",
    title: "패키지 관리",
    path: "/package",
  },
  {
    icon: "mdi-format-list-bulleted-square",
    title: "도메인 관리",
    sub: [
      {
        title: "전체 리스트",
        path: "/domain/all-list",
      },
      {
        title: "저장 리스트",
        path: "/domain/picked-list",
      },
    ],
  },
  {
    icon: "mdi-shape-outline",
    title: "카테고리 관리",
    path: "/category",
  },
  {
    icon: "mdi-note-search",
    title: "키워드",
    sub: [
      {
        title: "키워드 관리",
        path: "/keyword",
      },
      {
        title: "삭제된 키워드 관리",
        path: "/keyword-deleted-list",
      },
      {
        title: "구글에서 도메인의 키워드",
        path: "/keyword-domain",
      },
    ]
  },
  {
    icon: "mdi-wordpress",
    title: "워드프레스",
    sub: [
      // {
      //   title: "콘텐츠 리스트",
      //   path: "/wordpress/content-list",
      // },
      {
        title: "WP 계정 관리",
        path: "/wordpress/account-manage",
      },
      {
        title: "로그 관리",
        path: "/wordpress/log-manage",
      },
      // {
      //   title: "통계 관리",
      //   path: "/wordpress/stat-manage",
      // },
      {
        title: "콘텐츠 생성",
        path: "/wordpress/content-add",
      },
      {
        title: "콘텐츠 생성 (자동)",
        path: "/wordpress/content-add-auto",
      },
      {
        title: "콘텐츠 생성 (랜덤)",
        path: "/wordpress/content-add-random",
      },
      {
        title: "작업 대기 목록",
        path: "/wordpress/job-queue-list",
      },
    ],
  },
  {
    icon: "mdi-poll",
    title: "통계",
    sub: [
      {
        title: "국가별 접속 리포트",
        path: "/report/country",
      },
      {
        title: "방문 리포트",
        path: "/report/visit",
      },
      {
        title: "컨텐츠 리포트",
        path: "/report/content",
      },
      {
        title: "컨텐츠 생성 리포트",
        path: "/report/generated",
      },
      {
        title: "컨텐츠 생성 주간 리포트",
        path: "/report/generated-weekly",
      },
      {
        title: "색인 리포트",
        path: "/report/index",
      },
    ],
  },
  {
    icon: "mdi-keyboard",
    title: "프롬프트 관리",
    path: "/prompt",
  },
  {
    icon: "mdi-chat-processing-outline",
    title: "챗GPT API 테스트",
    path: "/gpt",
  },

  // {
  //   icon: "mdi-chart-bar",
  //   title: "통계",
  //   sub: [
  //     {
  //       title: "도메인 통계",
  //       path: "/domain-stat",
  //     },
  //     {
  //       title: "봇 통계",
  //       path: "/bot-stat",
  //     },
  //   ],
  // },
];
