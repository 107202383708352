import axios from "axios";
import store from "@/store";
import router from "@/router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

const instancePySV = axios.create({
  baseURL: process.env.VUE_APP_PY_API_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${store.getters["auth/authToken"]}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      store.dispatch("auth/logout");
      router.push("/login").catch(() => {});
    }
    return Promise.reject(error);
  }
);

/**
 * 로그인
 */
//로그인
export function loginApi(loginData) {
  return instance.post(`/users/login`, loginData);
}
//새로고침 시 유저 정보
export function fetchAdmin() {
  return instance.get(`/users/me`);
}
export function getUserDetails(id) {
  return instance.get(`/users/${id}`);
}
export function getUserList(params) {
  return instance.get(`/users`, { params });
}

/**
 * 계정 관리
 */
//계정 리스트 조회
export function getAccountList(params) {
  return instance.get(`/accounts`, { params });
}
//단일 계정 조회
export function getAccountDetails(id) {
  return instance.get(`/accounts/${id}`);
}
//계정 추가
export function createAccount(params) {
  return instance.post(`/accounts`, params);
}
//계정 삭제
export function deleteAccount(id) {
  return instance.delete(`/accounts/${id}`);
}
//단일 계정 수정
export function UpdateAccountDetails(id, params) {
  return instance.patch(`/accounts/${id}`, params);
}

export function getPackageList(params) {
  return instance.get(`/packages`, { params });
}
export function getPackageDetails(id) {
  return instance.get(`/packages/${id}`);
}
export function createPackage(params) {
  return instance.post(`/packages`, params);
}
export function deletePackage(id) {
  return instance.delete(`/packages/${id}`);
}
export function UpdatePackageDetails(id, params) {
  return instance.patch(`/packages/${id}`, params);
}

export function getModuleList(params) {
  return instance.get(`/modules`, { params });
}

/**
 * 도메인 관리 - 전체 리스트
 */
//전체 리스트 조회
export function getAllDomainList(params) {
  return instance.get(`/domainData`, { params });
}
//도메인 저장하기
export function addToPickedList(params) {
  return instance.post(`/domainData/pick`, params);
}

/**
 * 도메인 관리 - 저장 리스트
 */
//저장 리스트 조회
export function getPickedDomainList(params) {
  return instance.get(`/domainInfo`, { params });
}

/**
 * 카테고리 관리
 */
//카테고리 리스트 조회
export function getCategoryList(params) {
  return instance.get(`/categories`, { params });
}
//카테고리 추가
export function createCategory(params) {
  return instance.post(`/categories`, params);
}
//카테고리 삭제
export function deleteCategory(id) {
  return instance.delete(`/categories/${id}`);
}
//카테고리 수정
export function updateCategory(id, params) {
  return instance.patch(`/categories/${id}`, params);
}

/**
 * 키워드 관리
 */
//키워드 리스트 조회
export function getKeywordList(params) {
  return instance.get(`/keywords`, { params });
}
// export function analyzeDomain(params) {
//   return instance.post(`/keywords/analyze/domain`, { domain:params });
// }
export function analyzeDomain(params) {
  return instancePySV.post(`/analyze_domain?api_key=YBnIOzGMa3_bZe_XJkR6Tw`, { domain:params });
}
export function getKeywordDeletedList(params) {
  return instance.get(`/keywords/deleted-list`, { params });
}
//키워드 추가
export function createKeyword(params) {
  return instance.post(`/keywords`, params);
}
//키워드 삭제
export function deleteKeyword(id) {
  return instance.delete(`/keywords/${id}`);
}
//키워드 다중 삭제
export function deleteMultipleKeywords(params) {
  return instance.delete(`/keywords/multiple`, params);
}
export function restoreMultipleKeywords(params) {
  return instance.delete(`/keywords/restore/multiple`, params);
}
//키워드 단일 조회
export function getSingleKeyword(id) {
  return instance.get(`/keywords/${id}`);
}
//키워드에 도메인 카테고리 추가
export function addCategoryToKeyword(id, params) {
  return instance.post(`/keywords/${id}/attach-category`, params);
}
//키워드에 도메인 카테고리 삭제
export function removeCategoryFromKeyword(id, params) {
  return instance.delete(`/keywords/${id}/detach-category`, params);
}
//키워드 엑셀 업로드
export function uploadKeywordExcel(params) {
  return instance.post(`/keywords/import`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    }}
  );
}

/**
 * 워드프레스 관리 - WP 계정 관리
 */
//워드프레스 계정 리스트 조회
export function getWpAccountList(params) {
  return instance.get(`/wp-accounts`, { params });
}
export function getWpAccountListAll(params) {
  return instance.get(`/wp-accounts/list/All`, { params });
}
//워드프레스 계정 생성
export function createWpAccount(params) {
  return instance.post(`/wp-accounts`, params);
}
//워드프레스 계정 삭제
export function deleteWpAccount(id) {
  return instance.delete(`/wp-accounts/${id}`);
}
//워드프레스 계정 수정
export function updateWpAccount(id, params) {
  return instance.patch(`/wp-accounts/${id}`, params);
}
//워드프레스 계정 단일 조회
export function getSingleWpAccount(id) {
  return instance.get(`/wp-accounts/${id}`);
}
//워드프레스 계정에 도메인 카테고리 추가
export function addCategoryToWpAccount(id, params) {
  return instance.post(`/wp-accounts/${id}/attach-category`, params);
}
//워드프레스 계정에 도메인 카테고리 삭제
export function removeCategoryFromWpAccount(id, params) {
  return instance.delete(`/wp-accounts/${id}/detach-category`, params);
}
//워드프레스 타이틀 변경
export function ChangeWpTitle(params) {
  return instance.post(`/wordpress/update-title`, params);
}
//게시물 카테고리 추가
export function CreateWpCategories(params) {
  return instance.post(`/wordpress/categories`, params);
}

/**
 * 워드프레스 관리 - 로그 리스트
 */
//로그 리스트 조회
export function getLogList(params) {
  return instance.get(`/wp-logs`, { params });
}

export function getJobLogList(params) {
  return instance.get(`/wp-logs/job-queue-list`, { params });
}

/**
 * 워드프레스 관리 - 콘텐츠 생성
 */
//프롬프트 단일 조회
export function getSinglePrompt(id) {
  return instance.get(`/prompts/${id}`);
}
//챗gpt 프롬프트 던지기
export function askQuestion(params) {
  return instance.post(`/chatgpt`, params);
}
//구글 검색
export function getGoogleSearchResult(text) {
  return instance.get(`/search/?q=${text}`);
}
//워드프레스 글쓰기
export function writeWordpress(params) {
  return instance.post(`/wordpress`, params);
}
//워드프레스 계정별 카테고리 리스트 조회
export function getWpCategoryList(params) {
  return instance.get(`/wordpress/categories`, { params });
}

/**
 * 워드프레스 관리 - 콘텐츠 생성
 */
//create auto-recommend article
export function createAutoRecommendArticle(params) {
  return instance.post(`/articles/auto-recommend`, params);
}
//create auto-recommend article by optimize
export function createAutoRecommendArticleByOptimize(params) {
  return instance.post(`/articles/auto-recommend-by-optimize`, params);
}
export function createAutoRecommendArticleByOptimizeGPT4Turbo(params) {
  return instance.post(`/articles/auto-recommend-by-optimize-gpt-4-turbo`, params);
}
//create multiple auto-recommend article
export function createMultipleAutoRecommendArticle(params) {
  return instance.post(`/articles/multiple-auto-recommend`, params);
}
export function createMultipleAutoRecommendArticleGPT4Turbo(params) {
  return instance.post(`/articles/multiple-auto-recommend-gpt-4-turbo`, params);
}
//cancel all job queue
export function cancelAllJobQueue() {
  return instance.get(`/wp-logs/cancel-job-queue-list`);
}
//create multiple auto-recommend article by optimize
export function createMultipleAutoRecommendArticleByOptimize(params) {
  return instance.post(`/articles/multiple-auto-recommend-by-optimize`, params);
}
export function createMultipleAutoRecommendArticleByOptimizeGPT4Turbo(params) {
  return instance.post(`/articles/multiple-auto-recommend-by-optimize-gpt-4-turbo`, params);
}
//create multiple auto-recommend article by optimize - today
export function createMultipleAutoRecommendArticleByOptimizeToday(params) {
  return instance.post(`/articles/multiple-auto-recommend-by-optimize-today`, params);
}
export function createMultipleAutoRecommendArticleByOptimizeTodayGPT4Turbo(params) {
  return instance.post(`/articles/multiple-auto-recommend-by-optimize-today-gpt-4-turbo`, params);
}
//create filtered multiple auto-recommend article
export function createFilteredMultipleAutoRecommendArticle(params) {
  return instance.post(`/articles/filtered-multiple-auto-recommend`, params);
}
export function createFilteredMultipleAutoRecommendArticleGPT4Turbo(params) {
  return instance.post(`/articles/filtered-multiple-auto-recommend-gpt-4-turbo`, params);
}
//create single article with domain, keyword, and category
export function createSingleArticle(params) {
  return instance.post(`/articles/single`, params);
}
export function createSingleArticleByOptimize(params) {
  return instance.post(`/articles/single-by-optimize`, params);
}
export function createSingleArticleByOptimizeGPT4Turbo(params) {
  return instance.post(`/articles/single-by-optimize-gpt-4-turbo`, params);
}

/**
 * 통계 - 국가별 접속 리포트
 */
export function getCountryReport(params) {
  return instance.get(`/reports/country`, { params });
}

/**
 * 통계 - 방문자 접속 리포트
 */
//전체방문자
export function getVisitReport(params) {
  return instance.get(`/reports/visit`, { params });
}
//일자별 방문자 상세
export function getVisitDetailReport(params) {
  return instance.get(`/reports/visit/detail`, { params });
}

/**
 * 통계 - 컨텐츠 리포트
 */
export function getContentReport(params) {
  return instance.get(`/reports/content`, { params });
}

/**
 * 통계 - 컨텐츠 생성 리포트
 */
export function getGeneratedReport(params) {
  return instance.get(`/reports/generated`, { params });
}

/**
 * 통계 - 컨텐츠 생성 주간 리포트
 */
export function getGeneratedWeeklyReport(params) {
  return instance.get(`/reports/generated-weekly`, { params });
}

/**
 * 통계 - 색인 리포트
 */
export function getIndexReport(params) {
  return instance.get(`/reports/index`, { params });
}

/**
 * 프롬프트 관리
 */
//프롬프트 조회
export function getPromptList() {
  return instance.get(`/prompts`);
}
//프롬프트 수정
export function updatePrompt(id, params) {
  return instance.patch(`/prompts/${id}`, params);
}

/**
 * 유저 히스토리
 */
//마지막 요청시간 - multiple
export function getLastTimeOfMultiple() {
  return instance.get(`/user-history/multiple-last`);
}
export function getLastTimeOfMultipleGPT4Turbo() {
  return instance.get(`/user-history/multiple-last-gpt-4-turbo`);
}
//마지막 요청시간 - filtered
export function getLastTimeOfFiltered() {
  return instance.get(`/user-history/filtered-last`);
}
export function getLastTimeOfFilteredGPT4Turbo() {
  return instance.get(`/user-history/filtered-last-gpt-4-turbo`);
}
//마지막 요청시간 - multiple by optimize
export function getLastTimeOfMultipleByOptimize() {
  return instance.get(`/user-history/multiple-last-by-optimize`);
}
export function getLastTimeOfMultipleByOptimizeGPT4Turbo() {
  return instance.get(`/user-history/multiple-last-by-optimize-gpt-4-turbo`);
}
//마지막 요청시간 - multiple by optimize - today
export function getLastTimeOfMultipleByOptimizeToday() {
  return instance.get(`/user-history/multiple-last-by-optimize-today`);
}
export function getLastTimeOfMultipleByOptimizeTodayGPT4Turbo() {
  return instance.get(`/user-history/multiple-last-by-optimize-today-gpt-4-turbo`);
}