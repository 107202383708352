<template>
  <v-app>
    <v-navigation-drawer
      app
      clipped
      v-if="authenticated"
      v-model="drawer"
      class="customShadow"
    >
      <v-list class="pa-3" dense>
        <div v-for="item in menu" :key="item.title" class="mb-2">
          <!-- 서브메뉴 있는 경우 -->
          <template v-if="item.sub">
            <v-list-group :value="true" :prepend-icon="item.icon" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="child in item.sub"
                :key="child.title"
                :to="child.path"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <!-- 서브메뉴 없는 경우 -->
          <template v-else>
            <v-list-item :to="item.path" color="primary">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app v-if="authenticated" clipped-left class="customShadow">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>SEO 관리 콘솔</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="primary">
            <v-icon large>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="primary" size="50">
              <span class="white--text">{{ authenticated.name }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ authenticated.name }} 님
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ authenticated.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-2"></v-divider>
          <v-list-item @click="logoutAction">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>로그아웃</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main style="background: #f5f6f8">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { menu } from "@/utils/menu";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  data: () => ({
    menu,
    drawer: null,
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authUser",
    }),
  },
  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),
  },
};
</script>

<style>
.customShadow {
  background: #fff !important;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%) !important;
}
.v-navigation-drawer__border {
  display: none;
}
.basicCard {
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%) !important;
}
</style>
